import BannerHeader from "pages/common/BannerHeader"
import WhyNew from "pages/product/Common/WhyNew"
import GetStart from "pages/common/GetStart"
import WhatWeDoV2 from "pages/service/Common/WhatWeDoV2"
import LogoFooter from "pages/service/Common/LogoFooter"

import new1 from "images/Service/Store/service-2-management-1.png"
import new2 from "images/Service/Store/service-2-management-2.png"
import new3 from "images/Service/Store/service-2-management-3.png"

import logo1 from "images/Service/Store/logo-1.png"
import logo2 from "images/Service/Store/logo-2.png"
import logo3 from "images/Service/Store/logo-3.png"
import logo4 from "images/Service/Store/logo-4.png"
import logo5 from "images/Service/Store/logo-5.png"
import imageBanner1 from "images/Service/Store/service-2-mid-banner.png"
import { StaticImage } from "gatsby-plugin-image"
import { useFormatter } from "helpers/i18n"
import bannerMobile from "images/Service/Store/service-2-banner-mobile.png"
import ContentWrapper from "pages/common/ContentWrapper"

export default function Store() {
  const banner = "../../../images/Service/Store/service-2-banner.png"
  const { __ } = useFormatter()

  const contents = [
    {
      id: 1,
      title: __({
        defaultMessage: "Store Onboarding & Operation",
      }),

      content: __({
        defaultMessage:
          "With our efficient working process, we make sure your online store will be set up fast and on time.",
      }),
    },
    {
      id: 2,
      title: __({
        defaultMessage: "Store Merchandising & Promotion Setup",
      }),

      content: __({
        defaultMessage:
          "Our expertise focuses on need-base assortment, visibility mapping, diversified and dynamic promotion setup.",
      }),
    },
    {
      id: 3,
      title: __({
        defaultMessage: "Campaign Planning & Management",
      }),
      content: __({
        defaultMessage:
          "We implement tactics that grow online sales and improve customer lifetime value.",
      }),
    },
  ]

  return (
    <>
      <div>
        <BannerHeader
          breadcrumb={[
            {
              label: `${__({ defaultMessage: "Home/" })}`,
              url: "/",
            },
            {
              label: `${__({ defaultMessage: "Services/" })}`,
            },
            {
              label: `${__({
                defaultMessage: "E-store Management",
              })}`,
            },
          ]}
          banner={
            <StaticImage
              layout="fullWidth"
              src={banner}
              alt="E-store Management"
              decoding="async"
              fetchpriority="high"
              quality={100}
            />
          }
          mobileBanner={
            <img src={bannerMobile} alt="" style={{ width: "100%" }} />
          }
          logo={""}
          listTitle={[`${__({ defaultMessage: "E-store Management" })}`]}
          description={`${__({
            defaultMessage:
              "We excel at marketplace tools to generate leads, consistently updating content to keep your audience buzzing about your brand, while leveraging data to execute strategies that would lead to higher conversion.",
          })}`}
        />
        {[
          <WhyNew
            title={`${__({ defaultMessage: "Key Account Management" })}`}
            description={`${__({
              defaultMessage:
                "We make decisions regarding promotional strategies, pricing and product lines to convert browsers into buyers.",
            })}`}
            list={[
              {
                image: new1,
                title: `${__({
                  defaultMessage: "Account & Business Management",
                })}`,
                description: `${__({
                  defaultMessage:
                    "OnPoint dedicated teams will consult and develop account strategy for each brand. Our relevant departments closely collaborate to implement key actions. We will also manage account P&L to ensure achieving revenue target at lower expense.",
                })}`,
              },
              {
                image: new2,
                title: `${__({
                  defaultMessage: "Assortment & Promotion Planning",
                })}`,

                description: `${__({
                  defaultMessage:
                    "We select and optimize assortment portfolio based on demand forecasting and operational cost. We also use dynamic pricing and advanced promotion scheme to stay competitive in the market.",
                })}`,
              },
              {
                image: new3,
                title: `${__({
                  defaultMessage: "Co-brand Partnership Enhancement",
                })}`,
                description: `${__({
                  defaultMessage:
                    "We leverage portfolio of current clients to drive co-branding campaigns efficiently and measure the effectiveness through a wide range of shopper data.",
                })}`,
              },
            ]}
          />,
          <WhatWeDoV2
            contents={contents}
            image={imageBanner1}
            customClass="udbnrebllg-2"
          />,
        ].map((component, key) => (
          <section key={key}>
            <div className="reveal">{component}</div>
          </section>
        ))}
        <ContentWrapper customClass="reduce-margin-top-500">
          <LogoFooter
            title={`${__({ defaultMessage: "Marketplace Platform Partners" })}`}
            description={`${__({
              defaultMessage:
                "We help you tap into the growth potential of the leading marketplace platforms.",
            })}`}
            list={[logo1, logo2, logo3, logo4, logo5]}
          />
        </ContentWrapper>
        <GetStart />
      </div>
    </>
  )
}
