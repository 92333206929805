import ContainerMain from "src/pages/common/ContainerMain"
import "./WhatWeDoV2.scss"
import { useFormatter } from "helpers/i18n"

export default function WhatWeDo({
  contents = [],
  image = "",
  customClass = "",
}) {
  const { __ } = useFormatter()
  return (
    <>
      <div className="wmwfrxhexu" style={{ marginTop: 64 }}>
        <div className="pxiledsbwb">
          <div className="yvrkhtigjd">
            {__({
              defaultMessage: "Campaign & Merchandise Management",
            })}
          </div>
          <div className="wzvrqwyocu">
            {__({
              defaultMessage:
                "Our team ensures onsite visibilities for your e-commerce campaigns to achieve your targets for traffic and conversion rate.",
            })}
          </div>
        </div>
        <div className="uqajkgndxj">
          <ContainerMain alignType="right bjtvjkgcai-wrapper">
            <div className="bjtvjkgcai">
              <div className={`udbnrebllg ${customClass}`}>
                <div className="aqalpfffcm">
                  {contents.map(content => {
                    return (
                      <div className="pcxsqgpfpk" key={content.id}>
                        <div className="xvlobbatbf">
                          <div className="ixucicuvbe">{content.title}</div>
                          <div className="qkyrzxwfqt">{content.content}</div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="xifvmztqmd">
                <img alt="" className="oejkicvxfh" src={image} />
              </div>
            </div>
          </ContainerMain>
        </div>
      </div>
    </>
  )
}
