import ContainerMain from "src/pages/common/ContainerMain"
import Content from "src/pages/common/Content"
import "./WhyNew.scss"
import ImageZoomEffect from "pages/common/ImageZoomEffect"
import { useFormatter } from "helpers/i18n"

export default function WhyNew({ list, title, description }) {
  const { __ } = useFormatter()
  return (
    <>
      <div className="snElGSLNmQ">
        <ContainerMain>
          <div className="NnSxBIsuoI">
            <div className="MMfVvhsLVK section-title">
              {title ||
                `${__({ defaultMessage: "Creative & Content Production" })}`}
            </div>
            {description ? (
              <div className="hctcwvbzis">{description}</div>
            ) : null}
            <Content>
              <div className="XTExbngyLb">
                {list
                  ? list.map((item, key) => {
                      return (
                        <div
                          className={`VaquxsnUKk VaquxsnUKk-${key}`}
                          key={key}
                        >
                          <div className="vQcAdXDytg">
                            <ImageZoomEffect
                              alt=""
                              className="qJNLDAUfGI"
                              src={item?.image}
                            />
                          </div>
                          <div className="JsYXPtPyeG">{item?.title}</div>
                          <div className="EBINZolfnD">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item?.description,
                              }}
                            ></div>
                          </div>
                        </div>
                      )
                    })
                  : null}
              </div>
            </Content>
          </div>
        </ContainerMain>
      </div>
    </>
  )
}
